import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import PiralInstance from "./PiralInstance";
import { config } from "./config";
const piralBackendGetData = config.PIRAL_BACKEND.startsWith('$') ? `${config.PIRAL_BACKEND_FALLBACK}getData` : `${config.PIRAL_BACKEND}getData`;
export default () => {
    const urlParams = new URL(document.location.href).searchParams;
    const mode = urlParams.get("mode");
    let configID = urlParams.get("config");
    const componentID = urlParams.get("componentID");
    const urlProps = urlParams.get("props");
    if (!configID && !componentID) {
        return "Error: Need configID or componentID - And a new text";
    }
    //const subdomain = Resolver.getSubDomain();
    let subdomain = getTemporarySubdomain();
    console.info("subdomain: " + subdomain);
    console.info("configID: " + configID);
    const [layout, setLayout] = useState(null);
    const [isReady, setReady] = useState(false);
    const [isFetchInProgress, setFetchInProgress] = useState(false);
    const [data, setData] = useState(null);
    let componentProps = {};
    useEffect(() => {
        if (layout && data) {
            setReady(true);
        }
    }, [isReady, layout]);
    // TODO MHA
    // if (urlProps && componentID) {
    //   const jsonProps = JSON.parse(urlProps);
    //   componentProps[componentID] = jsonProps;
    // }
    if (layout === null && mode === "preview") {
        setLayout("single");
        return;
    }
    if (!data) {
        if (isFetchInProgress === true) {
            return "Test1...";
        }
        // TODO MHA
        setLayout("single");
        setFetchInProgress(true);
        fetchData(configID, componentID, subdomain).then((data) => {
            // if (layout !== "single") {
            //   setLayout(data.layout || "single");
            // }
            setData(data);
            setReady(true);
            setFetchInProgress(false);
        }).catch((error) => {
            console.error(error);
        });
        return "Test2...";
    }
    if (!isReady) {
        return "Test3...";
    }
    // const monitoring = createMonitoring();
    // monitoring.recordPageView(location.pathname);
    return _jsx(PiralInstance, { data: data, subdomain: subdomain });
};
async function fetchData(configID, componentID, subdomain) {
    if (subdomain && subdomain.length > 0) {
        subdomain = subdomain + ".";
    }
    console.log("Piral Backend: ", piralBackendGetData);
    const resp = await fetch(piralBackendGetData, {
        method: 'POST',
        body: JSON.stringify({ config: configID, componentID })
    });
    const json = await resp.json();
    console.info("received data:", json);
    return json;
}
// TODO extremely temporary until we have a better solution
function getTemporarySubdomain() {
    let hostname = document.location.hostname;
    if (hostname.indexOf("piral.") !== 0) {
        return "";
    }
    hostname = hostname.replace("piral.", "").replace("olive-team.com", "").replace(".", "");
    console.info("subdomain: " + hostname);
    return hostname;
}
