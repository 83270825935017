import AbstractDispatcher from "./AbstractDispatcher";
import { DefaultEndpointTypes, TYPE, } from "@olive/oli-types";
import BroadcastDispatcher from "./BroadcastDispatcher";
import WindowDispatcher from "./WindowDispatcher";
import { isString } from "lodash";
const SUPPORTED_TYPES = ["olive", "event", "jsAPI"];
export const CLIENT_DISPATCHER_ID = "client-dispatcher";
export default class ClientDispatcher extends AbstractDispatcher {
    constructor(wiringManager, eventManager, apiManager, connectivityManager) {
        super(wiringManager);
        this.eventManager = eventManager;
        this.apiManager = apiManager;
        this.connectivityManager = connectivityManager;
        this.broadcastDispatcher = new BroadcastDispatcher(wiringManager, connectivityManager);
        this.windowDispatcher = new WindowDispatcher(wiringManager, connectivityManager);
    }
    async dispatch(props) {
        let endpoint = this.wiringManager.get(props.id);
        console.log(endpoint, endpoint.type);
        switch (endpoint.type) {
            case TYPE.CONNECTOR:
            case "connectorInstance":
                const result = await this.dispatchConnector(endpoint, props.input);
                return result;
            case TYPE.ADAPTOR:
                return await this.dispatchAdaptor(endpoint, props.input);
            case TYPE.ENDPOINT:
                return await this.dispatchEndpoint(endpoint, props.input, props);
            default:
                console.warn(`Endpoint Type ${endpoint.type} not supported`);
                return `Endpoint Type ${endpoint.type} not supported`;
        }
    }
    canDispatch(props) {
        try {
            let { endpoint, type } = this.wiringManager.get(props.id);
            if ([TYPE.CONNECTOR, "connectorInstance"].includes(type)) {
                return true;
            }
            const endpointType = endpoint.type;
            return SUPPORTED_TYPES.includes(endpointType);
        }
        catch (error) {
            return false;
        }
    }
    async dispatchConnector(connector, input) {
        const connectorConfig = connector.connector
            ? connector.connector
            : connector;
        const sourceResult = await this.invokeSourceEndpoint(connectorConfig, input);
        const result = await this.invokeDestinationEndpoint(connectorConfig, sourceResult);
        return result;
    }
    async invokeSourceEndpoint(connectorConfig, input) {
        let source = {
            id: isString(connectorConfig.source)
                ? connectorConfig.source
                : connectorConfig.source.id,
            type: isString(connectorConfig.source)
                ? TYPE.CONNECTOR
                : connectorConfig.source.type,
        };
        //assuming type connector can only be a server call
        if (source.type === TYPE.CONNECTOR) {
            const serverResult = await this.connectivityManager.invoke({
                id: source.id,
                input,
            });
            return serverResult;
        }
        const sourceEndpoint = this.wiringManager.get(source.id);
        const sourceResult = await this.connectivityManager.invoke({
            id: sourceEndpoint.id,
            input,
        });
        return sourceResult;
    }
    async invokeDestinationEndpoint(connectorConfig, input) {
        let destination = {
            id: isString(connectorConfig.destination)
                ? connectorConfig.destination
                : connectorConfig.destination.instanceID ||
                    connectorConfig.destination.id,
            type: isString(connectorConfig.destination)
                ? TYPE.CONNECTOR
                : connectorConfig.destination.type,
        };
        //assuming type connector can only be a server call
        if (destination.type === TYPE.CONNECTOR) {
            const serverResult = await this.connectivityManager.invoke({
                id: destination.id,
                input,
            });
            return serverResult;
        }
        const destinationEndpoint = this.wiringManager.get(destination.id);
        const destinationResult = await this.connectivityManager.invoke({
            id: destinationEndpoint.instanceID || destinationEndpoint.id,
            input,
        });
        return destinationResult;
    }
    async dispatchEndpoint(endpoint, input, props) {
        console.log(endpoint);
        if (endpoint.endpoint.type === DefaultEndpointTypes.EVENT) {
            const eventEndpoint = endpoint.endpoint;
            return this.dispatchEvent(eventEndpoint.event.topic, input);
        }
        if (endpoint.endpoint.type === DefaultEndpointTypes.JS_API) {
            const { componentInstanceID } = endpoint.instanceParameters;
            const jsAPIEndpoint = endpoint.endpoint;
            return this.dispatchJSAPI({
                apiName: jsAPIEndpoint.jsAPI.api,
                componentInstanceID,
                input,
                props,
            });
        }
        if (this.broadcastDispatcher.canDispatch({ id: endpoint.id, endpoint })) {
            return await this.broadcastDispatcher.dispatch({
                id: endpoint.id,
                input,
            });
        }
        if (this.windowDispatcher.canDispatch({ id: endpoint.id, endpoint })) {
            return await this.windowDispatcher.dispatch({
                id: endpoint.id,
                input,
            });
        }
        console.warn("Currently only event endpoints are supported by client dispatcher!");
    }
    async dispatchAdaptor(adaptor, input) {
        // TODO
        return null;
    }
    dispatchEvent(topic, params) {
        this.eventManager.emit(topic, params);
    }
    dispatchJSAPI({ apiName, componentInstanceID, input, props, }) {
        const apiID = componentInstanceID + "-" + apiName;
        const api = this.apiManager.get(apiID);
        if (api) {
            return api(input, props);
        }
        else {
            console.error("Could not find api with id " + apiID);
        }
    }
}
