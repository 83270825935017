var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _WindowDispatcher_instances, _WindowDispatcher_onMessage, _WindowDispatcher_getEndpoint;
import AbstractDispatcher from "./AbstractDispatcher";
import { DefaultEndpointTypes } from "@olive/oli-types";
class WindowDispatcher extends AbstractDispatcher {
    constructor(wiringManager, connectivityManager) {
        super(wiringManager);
        _WindowDispatcher_instances.add(this);
        this.connectivityManager = connectivityManager;
        window.addEventListener('message', __classPrivateFieldGet(this, _WindowDispatcher_instances, "m", _WindowDispatcher_onMessage).bind(this));
    }
    canDispatch(props) {
        const endpoint = !props.endpoint ? __classPrivateFieldGet(this, _WindowDispatcher_instances, "m", _WindowDispatcher_getEndpoint).call(this, props.id) : props.endpoint;
        if (!endpoint) {
            return false;
        }
        const type = endpoint.endpoint.type;
        return type === DefaultEndpointTypes.WINDOW_MESSAGE;
    }
    async dispatch(props) {
        // const inputData = props.input as JSONObject;
        // const message = invoke({
        //   topic: inputData.topic as string,
        //   payload: inputData.payload as JSONObject
        // }, props.id);
        // return message;
        // TODO
        console.warn("window dispatcher.dispatch not yet implemented");
    }
}
_WindowDispatcher_instances = new WeakSet(), _WindowDispatcher_onMessage = async function _WindowDispatcher_onMessage(message) {
    console.log("#onMessage", message);
    const envelope = message.data;
    console.log("event to dispatch", envelope);
    const allWirings = this.wiringManager.getAll();
    const connectors = Object.keys(allWirings).map(key => allWirings[key]).filter(wiring => wiring.type === "connectorInstance");
    const matchingConnectors = connectors.filter(connector => connector.source.id === envelope.endpointID);
    console.log("matchingConnectors", matchingConnectors);
    const dispatchers = matchingConnectors.map(connector => this.connectivityManager.invoke({
        id: connector.destination.instanceID,
        input: envelope.payload
    }));
    const result = await Promise.all(dispatchers);
    console.log("dispatched: ", result);
}, _WindowDispatcher_getEndpoint = function _WindowDispatcher_getEndpoint(id) {
    if (!id) {
        return null;
    }
    return this.wiringManager.get(id);
};
export default WindowDispatcher;
